import { lazy } from "react";
import { DEFAULT_PATHS } from "config.js";

const home = {
    index: lazy(() => import("views/student/home/index")),
    plan: lazy(() => import("views/student/curriculumPlan/index")),
};

const studentOnlineExam = {
    exam: lazy(() => import("views/student/onlineExam/index")),
    detail: lazy(() => import("views/student/onlineExam/components/Detail")),
    view: lazy(() => import("views/student/onlineExam/components/View")),
    questions: lazy(() =>
        import("views/student/onlineExam/components/Questions")
    ),
};

const subject = {
    index: lazy(() => import("views/student/subject/index")),
    details: lazy(() => import("views/student/subject/pages/details/index")),
    view: lazy(() => import("views/student/subject/pages/details/view")),
    exam: lazy(() => import("views/student/subject/pages/exam")),
    examView: lazy(() => import("views/student/subject/pages/exam/examView")),
};

const studentStudyPlan = {
    index: lazy(() => import("views/student/studyPlan/index")),
};

const studentOnlineLesson = {
    groupCourse: lazy(() => import("views/student/course/index")),
    course: lazy(() => import("views/student/course/components/courseList")),
    info: lazy(() => import("views/student/course/components/courseDetail")),
    examQuestion: lazy(() => import("views/student/course/exam/examQuestions")),
    examView: lazy(() => import("views/student/course/exam/View")),
    crosswordView: lazy(() => import("views/student/course/crossword/index")),
};

const studentCouncilIndex = {
    index: lazy(() => import("views/student/council/index")),
};

const curriculumPlan = {
    index: lazy(() => import("views/student/curriculumPlan/index")),
};

const studentPodcast = {
    index: lazy(() => import("views/student/podcast/index")),
    view: lazy(() => import("views/student/podcast/components/view")),
};

const studentPerformance = {
    index: lazy(() => import("views/student/studentPerformance")),
};

const calendar = {
    index: lazy(() => import("views/student/calendar/index")),
};

const meetingTime = {
    index: lazy(() => import("views/student/meetingTime/index")),
};

const testing = {
    index: lazy(() => import("views/test")),
};

const user = {
    index: lazy(() => import("views/user/index")),
};

const help = {
    index: lazy(() => import("views/help")),
};

const chat = {
    index: lazy(() => import("views/chat")),
};

const theme = {
    index: lazy(() => import("views/theme/index")),
};

const appRoot = DEFAULT_PATHS.APP.endsWith("/")
    ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
    : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
    sidebarItems: [],
    //student web
    studentMenuItems: [
        {
            path: DEFAULT_PATHS.APP,
            exact: true,
            redirect: true,
            to: `${appRoot}/exam/index`,
        },
        {
            path: `${appRoot}/home/index`,
            label: "menu.home",
            icon: "quiz",
            exact: true,
            component: home.index,
            to: `${appRoot}/home/index`,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/exam/index`,
            label: "menu.onlineExam",
            icon: "quiz",
            exact: true,
            hideInMenu: false,
            component: studentOnlineExam.exam,
            to: `${appRoot}/exam/index`,
            isBack: true,
        },
        {
            path: `${appRoot}/subject/index`,
            label: "menu.subject",
            icon: "quiz",
            exact: true,
            isBack: true,
            component: subject.index,
        },
        {
            path: `${appRoot}/subject/details`,
            label: "subject.subjects",
            exact: true,
            isBack: true,
            component: subject.details,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/subject/details/view`,
            label: "menu.subject_details",
            exact: true,
            isBack: true,
            component: subject.view,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/subject/details/exam`,
            label: "menu.subject_details",
            exact: true,
            isBack: true,
            component: subject.exam,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/subject/details/exam-view`,
            label: "menu.subject_details",
            exact: true,
            isBack: true,
            component: subject.examView,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/lesson/index`,
            label: "menu.onlineLesson",
            icon: "book-open",
            hideInMenu: true,
            component: studentOnlineLesson.groupCourse,
        },
        {
            path: `${appRoot}/home/plan`,
            label: "Суралцаж байгаа хөтөлбөр",
            icon: "quiz",
            exact: true,
            hideInMenu: true,
            component: home.plan,
            isBack: true,
            to: `${appRoot}/home/plan`,
        },
        {
            path: `${appRoot}/curriculum/plan/index`,
            label: "Суралцахуйн төлөвлөгөө",
            icon: "quiz",
            exact: true,
            hideInMenu: true,
            component: curriculumPlan.index,
            to: `${appRoot}/curriculum/plan/index`,
        },
        {
            path: `${appRoot}/lesson/course`,
            component: studentOnlineLesson.course,
            menuHidden: false,
        },
        {
            path: `${appRoot}/lesson/crossword`,
            component: studentOnlineLesson.crosswordView,
            menuHidden: false,
        },
        {
            path: `${appRoot}/lesson/info`,
            component: studentOnlineLesson.info,
            menuHidden: false,
        },
        {
            path: `${appRoot}/exam/information`,
            component: studentOnlineExam.detail,
            label: "menu.onlineExam",
            hideInMenu: true,
            isBack: true,
        },
        {
            path: `${appRoot}/exam/exam`,
            component: studentOnlineExam.questions,
            label: "menu.onlineExam",
            hideInMenu: true,
            isBack: true,
        },
        {
            path: `${appRoot}/exam/view`,
            component: studentOnlineExam.view,
            label: "menu.testResults",
            hideInMenu: true,
            isBack: true,
        },
        {
            path: "/lesson/exam",
            component: studentOnlineLesson.examQuestion,
            menuHidden: false,
        },
        {
            path: "/lesson/exam-view",
            component: studentOnlineLesson.examView,
            menuHidden: false,
        },
        {
            path: `${appRoot}/study/index`,
            label: "menu.studyPlan",
            icon: "diploma",
            exact: true,
            hideInMenu: true,
            component: studentStudyPlan.index,
            to: `${appRoot}/study/index`,
        },
        {
            path: `${appRoot}/council/index`,
            label: "council.title",
            icon: "diploma",
            exact: true,
            hideInMenu: true,
            component: studentCouncilIndex.index,
        },
        {
            path: `${appRoot}/podcast/index`,
            label: "podcast.title",
            icon: "mic",
            exact: true,
            hideInMenu: true,
            component: studentPodcast.index,
        },
        {
            path: `${appRoot}/podcast/view`,
            component: studentPodcast.view,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/calendar`,
            label: "timetable.title",
            icon: "calendar",
            component: calendar.index,
            isBack: true,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/meeting-time`,
            label: "meetingTime.title",
            icon: "meetingTime",
            component: meetingTime.index,
            isBack: true,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/help`,
            label: "menu.help.title",
            component: help.index,
            hideInMenu: true,
            isBack: true,
        },
        {
            path: `${appRoot}/chat`,
            label: "menu.chat.title",
            icon: "chat",
            component: chat.index,
            isBack: true,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/student-performance`,
            label: "menu.studentPerformance.title",
            icon: "diploma",
            component: studentPerformance.index,
            isBack: true,
            hideInMenu: true,
        },
        {
            path: `${appRoot}/theme`,
            component: theme.index,
            hideInMenu: true,
        },
    ],
    //public student web
    publicStudentMenuItems: [
        {
            path: DEFAULT_PATHS.APP,
            exact: true,
            redirect: true,
            label: "menu.onlineExam",
            to: `${appRoot}/lesson/index`,
        },
        // {
        //     path: `${appRoot}/exam/index`,
        //     label: "menu.onlineExam",
        //     icon: "quiz",
        //     exact: true,
        //     component: studentOnlineExam.exam,
        //     to: `${appRoot}/exam/index`
        // },
        {
            path: `${appRoot}/lesson/index`,
            label: "menu.onlineLesson",
            icon: "book-open",
            component: studentOnlineLesson.groupCourse,
        },
        {
            path: `${appRoot}/podcast/index`,
            label: "podcast.title",
            icon: "mic",
            exact: true,
            hideInMenu: false,
            component: studentPodcast.index,
        },
        {
            path: `${appRoot}/lesson/course`,
            component: studentOnlineLesson.course,
            menuHidden: false,
        },
        {
            path: `${appRoot}/lesson/info`,
            component: studentOnlineLesson.info,
            menuHidden: false,
        },
        // {
        //     path: `${appRoot}/exam/information`,
        //     component: studentOnlineExam.detail,
        //     menuHidden: true
        // },
        // {
        //     path: `${appRoot}/exam/exam`,
        //     component: studentOnlineExam.questions,
        //     menuHidden: true
        // },
        // {
        //     path: `${appRoot}/exam/view`,
        //     component: studentOnlineExam.view,
        //     menuHidden: true
        // },
        {
            path: "/lesson/exam",
            component: studentOnlineLesson.examQuestion,
            menuHidden: false,
        },
        {
            path: "/lesson/exam-view",
            component: studentOnlineLesson.examView,
            menuHidden: false,
        },
        {
            path: `${appRoot}/podcast/view`,
            component: studentPodcast.view,
            hideInMenu: true,
        },
    ],
};
export default routesAndMenuItems;
